<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="医生团队名称：">
        <el-select v-model="formInline.teamCode" filterable>
          <el-option
            v-for="item in doctorAllTeamList"
            :key="item.teamCode"
            :value="item.teamCode"
            :label="item.teamName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="科室:" prop="deptCode">
        <el-select
          v-model="formInline.parentDeptCode"
          placeholder="请选择科室"
          filterable
          style="width: 45%"
        >
          <el-option
            v-for="item in parentDepartmentList"
            :key="item.deptCode"
            :value="item.deptCode"
            :label="item.deptName"
          />
        </el-select>
        <el-select
          v-if="formInline.parentDeptCode !== 'all'"
          v-model="formInline.deptCode"
          placeholder=""
          filterable
          style="width: 45%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.deptCode"
            :label="item.deptName"
            :value="item.deptCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="医生姓名：">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('BATCHSAVE', $route)"
          type="primary"
          :disabled="!isHaveData"
          @click="batchSave()"
        >
          批量保存
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
      @selection-change="handleSelectionChange"
    >
      >
      <el-table-column type="selection" width="55" />
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="teamName" label="医生团队名称" align="center" />
      <el-table-column prop="deptName" label="科室名称" align="center" />
      <el-table-column prop="mobile" label="手机号" align="center" />
      <el-table-column prop="doctorName" label="医生名称" align="center" />
      <el-table-column label="星期一" width="200" align="center">
        <el-table-column prop="mondayMorning" label="上午" align="center">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.mondayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="mondayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.mondayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="mondayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.mondayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期二" width="200" align="center">
        <el-table-column label="上午" align="center" prop="tuesdayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.tuesdayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="tuesdayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.tuesdayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="tuesdayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.tuesdayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期三" width="200" align="center">
        <el-table-column label="上午" align="center" prop="wednesdayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.wednesdayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="wednesdayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.wednesdayAfternoon" />
          </template> </el-table-column
        >,,
        <el-table-column label="晚上" align="center" prop="wednesdayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.wednesdayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期四" width="200" align="center">
        <el-table-column label="上午" align="center" prop="thursdayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.thursdayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="thursdayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.thursdayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="thursdayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.thursdayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期五" width="200" align="center">
        <el-table-column label="上午" align="center" prop="fridayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.fridayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="fridayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.fridayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="fridayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.fridayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期六" width="200" align="center">
        <el-table-column label="上午" align="center" prop="saturdayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.saturdayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="saturdayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.saturdayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="saturdayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.saturdayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="星期日" width="200" align="center">
        <el-table-column label="上午" align="center" prop="sundayMorning">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.sundayMorning" />
          </template>
        </el-table-column>
        <el-table-column label="下午" align="center" prop="sundayAfternoon">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.sundayAfternoon" />
          </template>
        </el-table-column>
        <el-table-column label="晚上" align="center" prop="sundayNight">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.sundayNight" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('SAVE', $route)"
            type="text"
            size="small"
            @click.native.prevent="saveData(scope.row)"
          >
            保存
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sourceNumRule } from '@/utils/verificationRule.js';
export default {
  name: 'TemplateMetain',
  data() {
    return {
      dialogVisible: false, // 控制修改弹框显示与否
      parentDepartmentList: [], // 一级科室列表
      departmentList: [{ deptCode: 'all', deptName: '全部' }], // 二级科室列表
      selectData: [], // 批量,
      isHaveData: false,
      loading: false,
      formInline: {
        parentDeptCode: 'all',
        teamCode: 'all',
        deptCode: 'all',
        doctorName: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      rule: {
        sourceNum: [
          { require: true, validator: sourceNumRule, trigger: 'blur' },
        ],
      },
      form: {
        sourceNum: 5,
      }, // 修改弹框时对象信息
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      allDepartmentList: (state) => state.allDepartmentList,
    }),
    ...mapState('doctorTeam', {
      doctorTeamList: (state) => state.allDoctorList,
    }),
    doctorAllTeamList() {
      console.log(this.doctorTeamList);
      var list = JSON.parse(JSON.stringify(this.doctorTeamList));
      list.unshift({
        teamCode: 'all',
        teamName: '全部',
      });
      return list;
    },
  },
  watch: {
    'formInline.parentDeptCode': function (value) {
      this.formInline.deptCode = 'all';
      this.departmentList = [{ deptCode: 'all', deptName: '全部' }];
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value) {
          this.form.parentDeptName = item.deptName;
          this.departmentList = [
            ...this.departmentList,
            ...JSON.parse(JSON.stringify(item.children)),
          ];
        }
      });
      // }
    },
  },
  mounted() {
    this.getTeamList();
    this.getDepartment();
    this.getRosterList();
  },
  methods: {
    ...mapActions('doctorTeam', ['queryList']),
    ...mapActions('basicDictionary', ['deptQueryList']),
    // 查询团队列表
    getTeamList() {
      this.queryList({});
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getRosterList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getRosterList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        parentDeptCode: 'all',
        teamCode: '',
        deptCode: 'all',
        doctorName: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getRosterList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getRosterList();
    },
    // 分页查询问诊类型列表
    getRosterList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      params.teamCode = params.teamCode === 'all' ? '' : params.teamCode;
      params.parentDeptCode =
        params.parentDeptCode === 'all' ? '' : params.parentDeptCode;
      params.deptCode = params.deptCode === 'all' ? '' : params.deptCode;
      this.$api
        .schedulePageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item) {
      this.dialogVisible = true;
      console.log(item);
    },
    // 批量保存
    batchSave() {
      this.$api.scheduleBatchEdit(this.selectData).then((res) => {
        if (res.code === 0) {
          this.$message.success('批量编辑排班成功');
          this.getRosterList();
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    async getDepartment() {
      await this.deptQueryList();
      console.log(this.allDepartmentList, 'allDepartmentList');
      this.listToTree(this.allDepartmentList);
    },
    // 科室列表转树
    listToTree(oldArr) {
      this.parentDepartmentList = [];
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode
          ? element.parentDeptCode
          : null;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });
      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      oldArr.unshift({ deptCode: 'all', deptName: '全部' });
      console.log(oldArr, '1111111111old');
      this.parentDepartmentList = oldArr;
    },
    // 选中的数据
    handleSelectionChange(val) {
      console.log(val);
      this.selectData = [];
      this.selectData = val;
      this.isHaveData = val.length > 0;
    },
    // 保存数据
    saveData(scope) {
      const param = {
        ...scope,
      };
      this.$api.scheduleEdit(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('编辑排班模板成功');
          this.getRosterList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
9
